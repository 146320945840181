
.ReactVirtualized__Grid {
  outline: none;
}


button:focus {
  outline:0;
}


